.notification-container{
    position: absolute;
    z-index: 99;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-60%);
    width: 320px;
    height: 600px;
    background-image:url('../../assets/images/phone_device.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .preview-content {
        height: 78%;
        border-radius: 15px;
        overflow: hidden;
        width: 85%;
        background-color:rgb(0, 9, 24);
        z-index: 101;
        margin-bottom: 75px;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        .notif{
            flex: 1;
            overflow-y: scroll;
            scrollbar-width: none;
            ::-webkit-scrollbar {
                display: none;
            }
            -ms-overflow-style: none;
            .header{
                display: flex;
                justify-content: space-between;
                p{
                    font-family: 'Industry';
                    padding: 15px;
                    color: white;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: bold;
                }
                button{
                    background-color: transparent;
                    border: none;color: white;
                    text-transform: uppercase;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 15px;
                    svg{
                        width: 30px;
                        height: 30px; 
                        color:  rgb(163, 163, 163)                   
                    }
                }
                border-bottom: 2px solid rgb(163, 163, 163);
            }
            .title{
                display: flex; 
                justify-content: space-between;
                padding: 10px;
                color:  rgb(163, 163, 163);
                font-weight: bold;
                font-size: 12px;
                border-bottom: 2px solid rgb(163, 163, 163);
                p{
                    font-family: 'Industry';
                }
            }
            .content{
                margin-top: 15px;
                padding: 15px;
                color: white;
                p{
                    margin: 0;
                }
                .content-date{
                    margin: 0;
                    font-size: 12px;
                    min-height: 12px;
                }
                .content-title{
                    margin-top: 10px;
                    min-height: 20px;
                    font-family: 'Industry';
                    text-transform: uppercase;
                    
                }
                .content-image{
                    margin-top: 30px;
                    width: 100%;

                }
                .content-message{
                    margin-top: 30px;
                    min-height: 21px;
                    font-family: 'Industry-Light';
                }
                .content-button{
                    color: white;
                    text-transform: uppercase;
                    margin-top: 50px;
                    margin-bottom: 20px;
                    width: 100%;
                    padding: 15px 10px;
                    background-color: transparent;
                    border: 1px solid rgb(255, 196, 0);
                    font-family: 'Industry-Light';
                    font-size: 12px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    p{
                        flex: 1;
                        height: 15px;
                    }
                    svg{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
        .mobile-menu{
            width: 100%;
            z-index: 101;
            display: flex;
            justify-content: center;
            position: relative;
            bottom: 0;
            img{
                max-width: 100%; 
                max-height: 100%; 
                height: auto;
                width: auto; 
                display: block; 
            }
        }
    }
   
}