
.preview-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sendTo{
        background-color: rgb(155, 149, 149);
        border-radius: 8px;
        padding: 10px;
        color: white;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 10px;
    }   
    button{
        width: 110px;
    }
}
.externalLinkWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;

    .checkbox{
        width: 122px;
    }
    .screen-picker{
        width: 178px;
    }
}

.datepickerWrapper{
    display: flex;
    gap: 30px;
    align-items: center;
    min-width: 820px;

}

.gameday-subtitle-wrapper{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.group-wrapper{
    display: flex;
    justify-content: space-between;
    flex-direction:row;
    gap: 30px
}

.games-wrapper{
    width: 33%;
    margin-top: 50px;
}

.gameday-subtitle{
    color: red;
    margin-top: 0;
    margin-bottom: 5px;
}

.Page-Loader{
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.datepicker{
    width: 330px;
}
.timepicker{
    width: 130px;
}